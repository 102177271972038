@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  body {
    font-family: "Inter", sans-serif !important;
    @apply bg-primary;
  }
  .auth-button {
    @apply inline-flex items-center justify-center py-3 text-sm font-medium rounded-lg focus:outline-none bg-primary text-white;
    &:hover {
      @apply opacity-80;
    }
  }

  .custom-tooltip {
    background-color: #202a89 !important;
    border-radius: 50px !important;
  }
}
